.button-list-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.button-list-inner-container {
  display: flex;
  align-items: center;
}

.button-list-button {
  background-color: black;
  color: white;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 20px;
  border-color: #4CAF50;
  margin: 0 10px;
}

.button-list-button:hover {
  background-color: #4CAF50;
  border-color: black;
}

.response-message {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: monospace;
  font-size: 15px;
  text-align: left;
  width: 100%;
  overflow-wrap: break-word; /* Ensures long words break */
  word-wrap: break-word; /* Ensures long words break */
  white-space: pre-wrap; /* Ensures white spaces are respected */
  overflow: auto; /* Adds scroll if content overflows */
}

.response-message-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}












