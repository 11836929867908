.button-list-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button-list-inner-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.button-list-button {
  background-color: #495057;
  color: white;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 20px;
  border-color: #4CAF50;
  margin: 0 10px;
  flex-grow: 1; /* Кнопки занимают всю доступную ширину */
  text-align: center; /* Выравнивание текста по центру */
}

.button-list-button:hover {
  background-color: #4CAF50;
  border-color: black;
}

.response-message {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: monospace;
  font-size: 15px;
  text-align: left;
  width: 100%;
  overflow-wrap: break-word; /* Ensures long words break */
  word-wrap: break-word; /* Ensures long words break */
  white-space: pre-wrap; /* Ensures white spaces are respected */
  overflow: auto; /* Adds scroll if content overflows */
}

.response-message-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px; /* Максимальная ширина для большого экрана */
  margin: 0 auto; /* Центрирование блока */
}










